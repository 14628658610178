import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'
import { withProtectionAndRedirection } from 'src/components/LicenseProtected/index.js'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser.js'
import River from 'src/modules/River/components/River'
import MyReports from 'src/modules/MyReports'

const RiverRoutes = () => (
  <Switch>
    {/* Main routes */}
    <Route
      exact
      path="/river/:key(my-fw)/:args*"
      component={withProtectionAndRedirection(
        withProtectionAndRedirection(River, {
          showFor: {
            license: licenseTypes.LICENSE_PLUS,
            paywall: 'plus_only',
            subtype: 'my_fw_stories'
          }
        }),
        {
          showFor: {
            authenticated: true,
            license: licenseTypes.LICENSE_PLUS,
            paywall: 'plus_only',
            subtype: 'my_fw_stories'
          },
          redirectForOthers: '/'
        }
      )}
    />

    <Route exact path="/river/:key(my-reports)/:args*" component={MyReports} />

    <Route
      exact
      path="/river/:key(story-watch|events|resources)/:args*"
      component={River}
    />
    <Route
      exact
      path="/river/:key(top-stories|iav|conference-news|related-news|related-analysis|tag|type|recent|all-reports|upcoming)/:args*"
      component={River}
    />

    {/* Search */}
    <Route exact path="/search" component={River} />
    <Route exact path="/search/:key/:args*" component={River} />

    {/* Redirect any legacy route */}
    <Redirect
      exact
      from="/stories/:key/:args*"
      to={`/river/:key/:args*${window.location.search}`}
    />

    {/* Defaults */}
    <Route exact path="/river/:key/:args*" component={River} />
  </Switch>
)

export default RiverRoutes
