import {
  createContainer,
  asValue,
  asClass,
  asFunction,
  Lifetime,
  InjectionMode
} from 'awilix'

import buildConfig from './config'
import FW5ML from './FW5ML'
import FW5MLInterceptors from './FW5ML/interceptors'
import UserRepository from './FW5ML/repositories/UserRepository'
import StoriesRepository from './FW5ML/repositories/StoriesRepository'
import ContactUsRepository from './FW5ML/repositories/ContactUsRepository'
import PaymentRepository from './FW5ML/repositories/PaymentRepository'
import CatalogsRepository from './FW5ML/repositories/CatalogsRepository'
import ConfigRepository from './FW5ML/repositories/ConfigRepository'
import SponsoredHeadlinesRepository from './FW5ML/repositories/SponsoredHeadlinesRepository'
import InterestsRepository from './FW5ML/repositories/InterestsRepository'
import NewslettersRepository from './FW5ML/repositories/NewslettersRepository'
import NoticesRepository from './FW5ML/repositories/NoticesRepository'
import FWReportsRepository from './FW5ML/repositories/FWReportsRepository'
import LandingPageRepository from './FW5ML/repositories/LandingPageRepository'
import LiveFeedRepository from './FW5ML/repositories/LiveFeedRepository'
import StoryParser from './FW5ML/parsers/StoryParser'
import AdInjector from './FW5ML/parsers/AdInjector'
import UserParser from './FW5ML/parsers/UserParser'
import SearchParser from './FW5ML/parsers/SearchParser'
import NewsletterParser from './FW5ML/parsers/NewsletterParser'
import AiMatchParser from './FW5ML/parsers/AiMatchParser'
import ContentRiverBuilder from './FW5ML/ContentRiverBuilder'
import LabelReplacer from './FW5ML/LabelReplacer'
import LegacyLinkResolver from './FW5ML/LegacyLinkResolver'
import Session from './session'
import SessionInterceptors from './session/interceptors'
import StorageService from './storage'
import DriverService from './session/driver'

import queryString from './queryString'
import LogRocketService from './LogRocketService'
import Ads from './Ads'
import AiMatch from './AiMatch'
import Piwik from './Piwik'
import Device from './Device'
import InterestMapper from './FW5ML/parsers/InterestMapper'
import SessionService from './SessionService'
import DougallML from './dougall/DougallML'
import DougallMLError from './dougall/DougallMLError'
import DougallMLInterceptors from './dougall/DougallMLInterceptors'
import DougallParser from './dougall/DougallParser'
import DougallService from './dougall/DougallService'
import DougallController from './dougall/DougallController'
import TranslationService from './TranslationService'
import PhoenixAuthService from './PhoenixAuthService'
import ClarityService from './clarity/ClarityService'
export default () => {
  const container = createContainer({ injectionMode: InjectionMode.CLASSIC })
  container.register({
    // Config
    config: asValue(buildConfig()),

    // FW5 ML Service
    Fw5MLApi: asClass(FW5ML, { lifetime: Lifetime.SINGLETON }),
    FW5MLInterceptors: asFunction(FW5MLInterceptors),
    UserRepository: asFunction(UserRepository),
    StoriesRepository: asFunction(StoriesRepository),
    ContactUsRepository: asFunction(ContactUsRepository),
    PaymentRepository: asFunction(PaymentRepository),
    NoticesRepository: asFunction(NoticesRepository),
    CatalogsRepository: asClass(CatalogsRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    ConfigRepository: asClass(ConfigRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    SponsoredHeadlinesRepository: asClass(SponsoredHeadlinesRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    InterestsRepository: asClass(InterestsRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    NewslettersRepository: asClass(NewslettersRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    LiveFeedRepository: asClass(LiveFeedRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    FWReportsRepository: asClass(FWReportsRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    LandingPageRepository: asClass(LandingPageRepository, {
      lifetime: Lifetime.SINGLETON
    }),
    StoryParser: asClass(StoryParser, { lifetime: Lifetime.SINGLETON }),
    AdInjector: asClass(AdInjector, { lifetime: Lifetime.SINGLETON }),
    UserParser: asClass(UserParser, { lifetime: Lifetime.SINGLETON }),
    SearchParser: asClass(SearchParser, { lifetime: Lifetime.SINGLETON }),
    NewsletterParser: asClass(NewsletterParser, {
      lifetime: Lifetime.SINGLETON
    }),
    AiMatchParser: asClass(AiMatchParser, {
      lifetime: Lifetime.SINGLETON
    }),
    ContentRiverBuilder: asClass(ContentRiverBuilder, {
      lifetime: Lifetime.SINGLETON
    }),
    InterestMapper: asClass(InterestMapper, { lifetime: Lifetime.SINGLETON }),
    LabelReplacer: asClass(LabelReplacer, { lifetime: Lifetime.SINGLETON }),
    LegacyLinkResolver: asClass(LegacyLinkResolver, {
      lifetime: Lifetime.SINGLETON
    }),

    // Session Service
    Session: asClass(Session, { lifetime: Lifetime.SINGLETON }),
    SessionInterceptors: asFunction(SessionInterceptors),

    // Query String Service
    queryString: asFunction(queryString),
    SessionService: asClass(SessionService, { lifetime: Lifetime.SINGLETON }),
    // Ads Service
    Ads: asClass(Ads, { lifetime: Lifetime.SINGLETON }),

    // AiMatch Service
    AiMatch: asClass(AiMatch, { lifetime: Lifetime.SINGLETON }),

    // Piwik Service
    Piwik: asClass(Piwik, { lifetime: Lifetime.SINGLETON }),

    LogRocketService: asClass(LogRocketService, {
      lifetime: Lifetime.SINGLETON
    }),

    // Id-key Service
    Device: asClass(Device, { lifetime: Lifetime.SINGLETON }),

    // Storage Service
    Storage: asFunction(StorageService),

    // Driver Service
    Driver: asFunction(DriverService),

    DougallML: asFunction(DougallML),
    DougallMLError: asClass(DougallMLError),
    DougallMLInterceptors: asFunction(DougallMLInterceptors),
    DougallService: asFunction(DougallService),
    DougallController: asFunction(DougallController),
    DougallParser: asFunction(DougallParser),
    TranslationService: asFunction(TranslationService),
    PhoenixAuthService: asFunction(PhoenixAuthService),
    ClarityService: asFunction(ClarityService)
  })
  // This provides a way to access dispatch and the store inside
  // connected services
  container.registerStore = store => {
    container.register({
      dispatch: asValue(action => store.dispatch(action)),
      getState: asValue(() => store.getState())
    })
  }
  window.di = container
  return container
}
