import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SessionContext } from './context'

const getSessionProviderValue = session => {
  console.log(session)
  return {
    ...session,
    userIsAuthenticated: () => session.checked && session.authenticated,
    userHasLicense: license =>
      session.checked &&
      session.authenticated &&
      session.user.license === license,
    getInterests: () =>
      session?.user?.interests ? session.user.interests.stated : null
  }
}

export const SessionProvider = ({ session, children }) => (
  <SessionContext.Provider value={getSessionProviderValue(session)}>
    {children}
  </SessionContext.Provider>
)

const mapStateToProps = state => ({
  session: state.session
})

SessionProvider.propTypes = {
  session: PropTypes.object,
  children: PropTypes.node
}

export default connect(mapStateToProps)(SessionProvider)
