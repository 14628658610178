import { withProtectionAndRedirection } from 'src/components/LicenseProtected/index.js'
import Login from 'src/modules/Login/LoginLandingPageContainer'
import Logout from 'src/modules/Session/LogoutContainer'
import Register from 'src/modules/Register/containers/RegisterLandingPageContainer'
import MyAccount from 'src/modules/MyAccount/components/MyAccountPage'
import River from 'src/modules/River'
import StaticPage from 'src/modules/Static'
import Captcha from 'src/modules/Captcha/CaptchaContainer'
import Landing from 'src/modules/GatedLandingPage/containers/LandingPageContainer'
import Consent from 'src/modules/GatedLandingPage/containers/ConsentPageContainer'
import OneClickSubscribe from 'src/modules/OneClickSubscribe/OneClickSubscribeContainer'
import ContactUs from 'src/modules/ContactUs/components/ContactUsPage'
import FW4MapComponent from 'src/modules/Views/RedirectFw4ToFw5'
import PayWallPage from 'src/modules/Benefits/components/PayWallPage'
import Payment from 'src/modules/Payment'
import ConfirmationPayment from 'src/modules/Payment/Confirmation'
import SaveSearch from 'src/modules/MySavedSearches/containers/SaveSearchFormContainer'
import Layout from 'src/modules/Layout/LayoutContainer'
import siteRoutes from 'src/siteConfig/routes.js'
import DougallFW from 'src/modules/DougallFW/DougallFW'
import OnboardingFlow from 'src/modules/OnboardingFlow'

export const getDynamicRoutes = (pages = []) => {
  return pages
    .filter(page => !page.exclude_from_routes)
    .map(page => {
      let component = Layout
      if (page?.withProtectionAndRedirection) {
        component = withProtectionAndRedirection(component, {
          ...page?.withProtectionAndRedirection
        })
      }
      return { path: page.uri_path, component, exact: true }
    })
}

const sharedRoutes = [
  {
    path: '/sign-in-email-sent',
    component: StaticPage('loginEmailSent'),
    exact: true
  },
  {
    path: '/confirmation-email-sent',
    component: StaticPage('deviceConfirmationSent'),
    exact: true
  },
  {
    path: '/register-email-sent',
    component: StaticPage('registerEmailSent'),
    exact: true
  },
  {
    path: '/device-limit',
    component: StaticPage('deviceLimit'),
    exact: true
  },
  {
    path: '/log-out',
    component: Logout,
    exact: true
  },
  {
    path: '/sign-in',
    component: withProtectionAndRedirection(Login, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/device-sign-in',
    component: withProtectionAndRedirection(Login, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/register',
    component: withProtectionAndRedirection(Register, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/my-account/:section?',
    component: withProtectionAndRedirection(MyAccount, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/search/:term?',
    component: withProtectionAndRedirection(River, {
      showFor: {
        authenticated: true,
        paywall: 'free_feature',
        subtype: 'search'
      }
    }),
    exact: true
  },
  {
    path: '/(stories|river)/:key/:args*',
    component: River,
    exact: true
  },
  {
    path: '/contact-us/:why',
    component: ContactUs,
    exact: true
  },
  {
    path: '/cookie-policy',
    component: StaticPage('cookies'),
    exact: true
  },
  {
    path: '/benefits',
    component: PayWallPage,
    exact: true
  },
  {
    path: '/captcha',
    component: withProtectionAndRedirection(Captcha, {
      showFor: { anonymous: true },
      redirectForOthers: '/'
    }),
    exact: true
  },
  {
    path: '/firstword-ai',
    component: withProtectionAndRedirection(DougallFW, {
      showFor: {
        requireAll: true,
        authenticated: true,
        featureFlag: 'dougallFw',
        paywall: 'ai_paywall',
        paywallFeature: 'Firstword AI'
      }
    }),
    exact: true
  },
  {
    path: '/register-newsletter-subscribe',
    component: withProtectionAndRedirection(OnboardingFlow, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/SEEAUKPrivacy',
    component: StaticPage('SEEAUKPrivacy'),
    exact: true
  },
  {
    path: '/CCPAPrivacyPolicy',
    component: StaticPage('CCPAPrivacyPolicy'),
    exact: true
  },
  {
    path: '/checkout',
    component: Payment,
    exact: true
  },
  {
    path: '/checkout/confirmation',
    component: ConfirmationPayment,
    exact: true
  },
  {
    path: '/newsletter-subscribe',
    component: OneClickSubscribe,
    exact: true
  },
  {
    path: '/save-search',
    component: withProtectionAndRedirection(SaveSearch, {
      showFor: { authenticated: true },
      redirectForOthers: '/sign-in'
    }),
    exact: true
  },
  {
    path: '/story/map/:fw4_story_id',
    component: FW4MapComponent,
    exact: true
  },
  {
    path: '/not-found',
    component: StaticPage('notFound'),
    exact: true
  },
  {
    path: '/consent',
    component: Consent,
    exact: true
  },
  {
    path: '/:landing_page_route',
    component: Landing,
    exact: true
  },
  {
    component: StaticPage('notFound')
  }
]

const routes = [...siteRoutes, ...sharedRoutes]
export default routes
